import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators'
import {
    PersonChangeRequestServiceProxy,
    GetPersonChangeRequestsOutput,
    IdentificationServiceProxy,
    MySironaServiceProxy,
    UserContactDto,
    CreateOrUpdateEmploymentChangeRequestInput,
    GetEmploymentsOutput,
    GetProfessionalLicencesOutput,
    UserEmploymentDto,
    UserProfessionalLicenceDto,
    IdentifyUserInput,
    UserIdentificationDto,
    GeneralFileDto,
    IdentificationStatusDto,
    PersonChangeRequestDto,
    CreatePersonChangeRequestDto,
    VerifyUserNameChangeInput,
    GetExistingPersonChangeRequestInfoOutput,
    GetExistingEmploymentChangeRequestInfoOutput,
    EmploymentChangeRequestDto,
    UserIdentificationFilesUploadInput
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { DateTime } from 'luxon';

@Injectable()
export class UserService extends AppComponentBase {
    identificationStatus: IdentificationStatusDto;
    user: UserContactDto;
    employments: UserEmploymentDto[] = [];
    professionalLicences: UserProfessionalLicenceDto[] = [];

    constructor(
        injector: Injector,
        private personChangeRequestService: PersonChangeRequestServiceProxy,
        private identificationService: IdentificationServiceProxy,
        private mySironaService: MySironaServiceProxy
    ) {
        super(injector);
    }

    getUser(): Observable<UserContactDto> {
        return this.mySironaService.getContactInfo()
            .pipe(tap((output: UserContactDto) => {
                this.user = output;
            }));
    };

    uploadIdentificationFiles(input: UserIdentificationFilesUploadInput): Observable<void> {
        return this.identificationService.uploadIdentificationFiles(input).pipe(tap(() => {
            this.user.userIdentificationStatus.identificationStatus = AppConsts.codes.identificationStatus.submitted;
        }));
    }

    deleteIdentificationFile(): Observable<void> {
        return this.identificationService.deleteIdentification().pipe(tap(() => {
            this.user.userIdentificationStatus.identificationStatus = AppConsts.codes.identificationStatus.none;
        }));
    }
    
    getProfessionalLicenceFile(professionalLicenceFileId: number): Observable<GeneralFileDto> {
        return this.mySironaService.getProfessionalLicenceFile(professionalLicenceFileId);
    }

    getMyIdentificationFile(docId: number): Observable<GeneralFileDto> {
        return this.identificationService.getMyIdentificationFile(docId);
    }

    getIdentificationFile(userIdentificationId: number, docId: number): Observable<GeneralFileDto> {
        return this.identificationService.getIdentificationFile(userIdentificationId, docId);
    }

    getIdentification(userIdentificationId: number): Observable<UserIdentificationDto> {
        return this.identificationService.getIdentification(userIdentificationId);
    }

    getIdentifications(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
        return this.identificationService.getIdentifications(filter, sorting, maxResultCount, skipCount);
    }

    identifyUser(input: IdentifyUserInput): Observable<void> {
        return this.identificationService.identifyUser(input);
    }

    createPersonChangeRequest(input: CreatePersonChangeRequestDto): Observable<void> {
        return this.personChangeRequestService.createPersonChangeRequest(input);
    }

    createEmploymentChangeRequest(input: CreateOrUpdateEmploymentChangeRequestInput): Observable<void> {
        return this.personChangeRequestService.createEmploymentChangeRequest(input);
    }

    getPersonChangeRequests(filter: string, sorting: string, maxResultCount: number, skipCount: number): Observable<GetPersonChangeRequestsOutput> {
       return this.personChangeRequestService.getPersonChangeRequests(filter, sorting, maxResultCount, skipCount);
    }

    getPersonChangeRequest(id: number): Observable<PersonChangeRequestDto> {
        return this.personChangeRequestService.getPersonChangeRequest(id);
    }
    
    getIdentificationStatus(refresh = false): Observable<IdentificationStatusDto> {
        if (!refresh && this.identificationStatus) {
            return of(this.identificationStatus);
        }

        return this.mySironaService.getIdentificationStatus()
            .pipe(tap(output => 
            this.identificationStatus = output.identificationStatus
        ), map((output) => output.identificationStatus));
    }

    verifyUserNameChange(input: VerifyUserNameChangeInput): Observable<void> {
        return this.identificationService.verifyUserNameChange(input);
    }

    getEmployments(): Observable<UserEmploymentDto[]> {
        return this.mySironaService.getEmployments()
            .pipe(tap((output: GetEmploymentsOutput) => {
                this.employments = output.items;
            }), map((output: GetEmploymentsOutput) => output.items));
    };

    getSponsorshipCount(): Observable<number> {
        return this.mySironaService.getSponsorshipCount().pipe(
                map(sponsorships => sponsorships.totalCount));
    }

    getEmploymentsCount(onlyActive: boolean): Observable<number> {
        return this.mySironaService.getEmploymentsCount().pipe(
                map(employments => onlyActive ? employments.activeCount : employments.totalCount));
    }

    getProfessionalLicences(): Observable<UserProfessionalLicenceDto[]> {
        return this.mySironaService.getProfessionalLicences()
            .pipe(tap((output: GetProfessionalLicencesOutput) => {
                this.professionalLicences = output.items;
            }), map((output: GetProfessionalLicencesOutput) => output.items));
    }

    getLicencesCount(onlyActive: boolean): Observable<number> {
        return this.mySironaService.getProfessionalLicencesCount().pipe(
            map(lic => onlyActive ? lic.activeCount : lic.totalCount));
    }

    getSubmittedEmploymentChangeRequests(): Observable<EmploymentChangeRequestDto[]> {
        return this.mySironaService.getSubmittedEmploymentChangeRequests();
    }

    getExistingEmploymentChangeRequestInfo(): Observable<GetExistingEmploymentChangeRequestInfoOutput> {
        return this.mySironaService.getExistingEmploymentChangeRequestInfo();
    }

    getExistingPersonChangeRequestInfo(): Observable<GetExistingPersonChangeRequestInfoOutput> {
        return this.mySironaService.getExistingPersonChangeRequestInfo();
    }

    hasAnyApplicationOrSponsorship(): Observable<boolean> {
        if (abp.auth.isGranted('Pages.Applicant')) {
            return this.mySironaService.hasAnyApplicationOrSponsorship();
        }

        return of(false);
    }

    isEmailConfirmed(): Observable<boolean> {
        if (this.user) {
            return of(this.user.isEmailConfirmed);
        }
        
        return this.mySironaService.isUserEmailConfirmed();
    }
}
