import { PsyRegEducationDto } from './../../../shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    GetPsyRegPersonDetailedOutput,
    GetPsyRegPersonOutput,
    PsyRegAuthorisationDto,
    PsyRegServiceProxy,
    SearchPsyRegPersonInput,
} from '@shared/service-proxies/service-proxies';
import { tap, delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { LocalizationService } from 'abp-ng2-module';
import { HealthRegisterBaseService } from './health-register-base.service';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class PsyRegService extends HealthRegisterBaseService {

    latestPsyRegPerson: GetPsyRegPersonDetailedOutput;

    constructor(
        protected datePipe: DatePipe,
        protected localization: LocalizationService,
        private psyregService: PsyRegServiceProxy,
    ) {
        super(datePipe, localization);
    }

    get fullName(): string {
        var person = this.latestPsyRegPerson?.psyRegPerson;
        var name = person?.firstName;

        if (person?.name) {
            if (name) {
                name = name + ' ' + person.name;
            } else {
                name = person.name;
            }
        }

        return name;
    }

    get birthYear(): string {
        return this.latestPsyRegPerson?.psyRegPerson.birthYear;
    }

    get nationality1(): string {
        return this.latestPsyRegPerson?.psyRegPerson.nationality1.sironaId;
    }

    get nationality2(): string {
        return this.latestPsyRegPerson?.psyRegPerson?.nationality2?.sironaId;
    }

    get queryTime(): DateTime {
        return this.latestPsyRegPerson?.psyRegPerson?.queryTime;
    }

    get languageSkillsAsCommaSeparatedList(): string {
        return null;
    }

    get hasInactiveLicences(): boolean {
        const licences = this.getLicences();
        return licences.some((l) => l.licenceStatus.sironaId !== AppConsts.codes.professionalLicenceStatus.granted);
    }

    getPerson(registerPersonId: number): Observable<GetPsyRegPersonOutput | string> {
        if (this.latestPsyRegPerson !== undefined && this.latestPsyRegPerson.psyRegPerson.id === registerPersonId) {
            return of(this.latestPsyRegPerson).pipe(delay(1));
        }

        return this.psyregService
            .get(registerPersonId)
            .pipe(tap((output: GetPsyRegPersonDetailedOutput) => (this.latestPsyRegPerson = output)));
    }

    getPersonDetailed(registerPersonId: number): Observable<GetPsyRegPersonDetailedOutput | string> {
        if (this.latestPsyRegPerson !== undefined && this.latestPsyRegPerson.psyRegPerson.id === registerPersonId) {
            return of(this.latestPsyRegPerson).pipe(delay(1));
        }

        return this.psyregService
            .getDetailed(registerPersonId)
            .pipe(tap((output: GetPsyRegPersonDetailedOutput) => (this.latestPsyRegPerson = output)));
    }

    search(input: SearchPsyRegPersonInput) {
        return this.psyregService.search(input);
    }

    getLicences(): PsyRegAuthorisationDto[] {
        return this.latestPsyRegPerson?.psyRegPerson.psyRegAuthorisations;
    }

    get diplomas(): string[] {
        let list: string[] = [];
        let diploma = this.latestPsyRegPerson?.psyRegPerson?.psyRegEducations?.filter(x => x.isPostgraduateTitle == false);
        if (diploma) {
            for (let i = 0; i < diploma.length; i++) {
                list.push(
                    `${this.localization.localize(diploma[i].profession.sironaId, this.lSource)}, ${this.resolveDate(diploma[i].issuingDate)}`
                );
            }
        }
        return list;
    }

    private displayCorrectDiplomaDate(diploma: PsyRegEducationDto): string {
        if (diploma.typeId == AppConsts.codes.diploma.psyreg.type.federalPsyReg) {
            return this.resolveDate(diploma.issuingDate);
        }

        return this.resolveDate(diploma.recognitionDate);
    }

    get normalLicences(): string[] {
        const list = [];
        let licences = this.getLicences()?.filter((l) => l.licenceType !== AppConsts.codes.ProfessionalLicenceType.ninetyDays);
        if (licences == null) return list;
        for (let i = 0; i < licences.length; i++) {
            list.push(
                `${this.localization.localize(licences[i].diplomaType?.healthRegisterDescription, this.lSource)},
                 ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                 ${this.resolveDate(licences[i].decisionDate)},
                 ${this.localization.localize(licences[i].licenceStatus?.healthRegisterDescription, this.lSource)}`
            );
        }
        return list;
    }

    get hasSensitiveData(): boolean {
        const dataDeservingProtection = this.latestPsyRegPerson.psyRegPerson.dataDeservingProtection;
        return dataDeservingProtection == true;
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return false;
    }

    get cantonalLicenceRestrictions(): string[] {
        return this.latestPsyRegPerson?.psyRegPerson.cantonalLicenceRestrictions;
    }

    getLanguage(psyRegId: number): Promise<string> {
        return new Promise((resolve) => {
            this.getPerson(psyRegId).subscribe((output: GetPsyRegPersonDetailedOutput) => {
                let language;
                const languageOfCorrespondence = output.psyRegPerson.languageOfCorrespondence.sironaId;
                if (
                    languageOfCorrespondence == AppConsts.codes.language.german ||
                    languageOfCorrespondence == AppConsts.codes.language.french
                ) {
                    language = languageOfCorrespondence;
                }
                resolve(language);
            });
        });
    }

    get birthDate(): string {
        return this.resolveDate(this.latestPsyRegPerson?.psyRegPerson.birthdate);
    }

    get dl90Licences(): string[] {
        const list = [];
        let licences = this.getLicences()?.filter(
            (l) => l.licenceType === AppConsts.codes.ProfessionalLicenceType.ninetyDays
        );
        if (licences == null) return list;
        for (let i = 0; i < licences.length; i++) {
            list.push(
                `${this.localization.localize(licences[i].diplomaType.sironaId, this.lSource)},
                 ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                 ${this.resolveDate(licences[i].decisionDate)}`
            );
        }
        return list;
    }

    get postgraduateTitles(): string[] {
        let list: string[] = [];
        let titles = this.latestPsyRegPerson?.psyRegPerson.psyRegEducations.filter(x => x.isPostgraduateTitle == true);
        if (titles) {
            for (let i = 0; i < titles.length; i++) {
                list.push(
                    this.localization.localize(titles[i].typeId, this.lSource) +
                    ' ' +
                    this.resolveLanguage(titles[i].postgraduateKindLabel) +
                    ', ' +
                    this.resolveDate(titles[i].issuingDate)
                );
            }
        }
        return list;
    }

    private displayCorrectPostgraduateTitleDate(postgraduateTitle: PsyRegEducationDto): string {
        if (postgraduateTitle.typeId === AppConsts.codes.psyreg.postgraduateTitle.type.federal) {
                return this.resolveDate(postgraduateTitle.issuingDate);
            }

        return this.resolveDate(postgraduateTitle.recognitionDate);
    }

}
