import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import {
    GetNaregPersonOutput,
    NaregServiceProxy,
    SearchNaregPersonInput,
    NaregLicenceDto,
    GetNaregPersonDetailedOutput,
} from '@shared/service-proxies/service-proxies';
import { LocalizationService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { HealthRegisterBaseService } from './health-register-base.service';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class NaregService extends HealthRegisterBaseService {

    latestNaregPerson: GetNaregPersonDetailedOutput;

    constructor(
        protected datePipe: DatePipe,
        protected localization: LocalizationService,
        private naregService: NaregServiceProxy,
    ) {
        super(datePipe, localization);
    }

    get hasInactiveLicences(): boolean {
        // active states
        const statuses = [AppConsts.codes.professionalLicenceStatus.granted, AppConsts.codes.professionalLicenceStatus.restriced];

        const licences = this.getLicences();
        return licences.some((l) => !statuses.includes(l.status.sironaId));
    }



    search(input: SearchNaregPersonInput) {
        return this.naregService.search(input);
    }

    getPerson(registerPersonId: number): Observable<GetNaregPersonOutput | string> {
        if (this.latestNaregPerson !== undefined && this.latestNaregPerson.naregPerson.id === registerPersonId) {
            return of(this.latestNaregPerson).pipe(delay(1));
        }

        return this.naregService
            .get(registerPersonId)
            .pipe(tap((output: GetNaregPersonDetailedOutput) => (this.latestNaregPerson = output)));
    }

    getPersonDetailed(registerPersonId: number): Observable<GetNaregPersonDetailedOutput | string> {
        if (this.latestNaregPerson !== undefined && this.latestNaregPerson.naregPerson.id === registerPersonId &&
            this.latestNaregPerson.naregPerson.naregSensitiveData == undefined) {
            return of(this.latestNaregPerson).pipe(delay(1));
        }

        return this.naregService
            .getDetailed(registerPersonId)
            .pipe(tap((output: GetNaregPersonDetailedOutput) => (this.latestNaregPerson = output)));
    }

    get postgraduateTitles(): string[] {
       return null;
    }

    get fullName(): string {
        var person = this.latestNaregPerson?.naregPerson;
        var name = person?.firstName;

        if (person?.lastName) {
            if (name) {
                name = name + ' ' + person.lastName;
            } else {
                name = person.lastName;
            }
        }

        return name;
    }

    get nationality1(): string {
        return this.latestNaregPerson?.naregPerson.country1.sironaId;
    }

    get nationality2(): string {
        return this.latestNaregPerson?.naregPerson.country2.sironaId;
    }

    get birthYear(): string {
        return this.latestNaregPerson?.naregPerson.birthYear;
    }

    get queryTime(): DateTime {
        return this.latestNaregPerson?.naregPerson.queryTime;
    }

    get languageSkillsAsCommaSeparatedList(): string {
        return null;
    }

    get diplomas(): string[] {
        const list: string[] = [];
        var diplome = this.latestNaregPerson?.naregPerson.naregDiploms;
        if (diplome) {
            for (let i = 0; i < diplome.length; i++) {
                if (diplome[i].profession.sironaId != null) {
                    list.push(
                        `${this.localization.localize(diplome[i].profession.sironaId, this.lSource)}, ${this.resolveDate(diplome[i].dateOfIssue)}`
                    );
                }
            }
        }
        return list;
    }

    get hasSensitiveData(): boolean {
        const noSensitiveDataCode = 10002;
        const sensitiveData = this.latestNaregPerson?.naregPerson?.naregSensitiveData?.filter(x => x.isDeleted == false && x.code.healthRegisterId != noSensitiveDataCode);
        return sensitiveData?.length > 0;
    }

    get birthDate(): string {
        return this.resolveDate(this.latestNaregPerson?.naregPerson?.dateOfBirth);
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return false;
    }

    get cantonalLicenceRestrictions(): string[] {
        return this.latestNaregPerson?.naregPerson?.cantonalLicenceRestrictions;
    }


    getLanguage(naregId: number): Promise<string> {
        return new Promise((resolve) => {
            this.getPerson(naregId).subscribe((output: GetNaregPersonDetailedOutput) => {
                const languageOfCorrespondence = output.naregPerson.correspondenceLanguage.sironaId;
                let language;
                if (languageOfCorrespondence == AppConsts.codes.language.german ||
                     languageOfCorrespondence == AppConsts.codes.language.french) {
                    language = languageOfCorrespondence;
                }
                resolve(language);
            });
        });
    }

    getLicences(): NaregLicenceDto[] {
        return this.latestNaregPerson?.naregPerson?.naregLicences;
    }

    get normalLicences(): string[] {
        const list = [];
        let licences = this.getLicences();
        if (licences) {
            licences = licences.filter((l) => l.licenceType.healthRegisterId !== AppConsts.codes.external.nareg.licence.type.dla90);
            for (let i = 0; i < licences.length; i++) {
                list.push(
                    `${this.localization.localize(licences[i].profession.sironaId, this.lSource)},
                     ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                     ${this.resolveDate(licences[i].dateOfIssue)},
                     ${this.resolveLanguage(licences[i].statusLabel)}`
                );
            }
        }
        return list;
    }

    get dl90Licences(): string[] {
        const list = [];
        let licences = this.getLicences();
        if (licences) {
            licences = licences.filter((l) => l.licenceType.healthRegisterId === AppConsts.codes.external.nareg.licence.type.dla90);
            for (let i = 0; i < licences.length; i++) {
                list.push(
                    `${this.localization.localize(licences[i].profession.sironaId, this.lSource)},
                     ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                     ${this.resolveDate(licences[i].dateOfIssue)}`
                );
            }
        }
        return list;
    }
}
